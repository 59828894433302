





























import TheFooter from "@/components/TheFooter.vue";
import TheNavigation from "@/components/TheNavigation.vue";
import TheScrollToTop from "@/components/TheScrollToTop.vue";
import { Component, Vue } from "vue-property-decorator";
import { debounce } from "lodash";
import { LAYOUT_MUTATIONS } from "@/store/modules/layout";
import TheLoadingProfileIndicator from "@/components/TheLoadingProfileIndicator.vue";
import { namespace } from "vuex-class";
import { ProfileState } from "@/store/modules/user";
import TheProfileError from "@/components/TheProfileError.vue";
import TheZendeskButton from "@/components/TheZendeskButton.vue";

const user = namespace("user");
const layout = namespace("layout");
@Component({
  components: {
    TheZendeskButton,
    TheScrollToTop,
    TheFooter,
    TheNavigation,
    TheLoadingProfileIndicator,
    TheProfileError,
  },
})
export default class App extends Vue {
  @layout.Getter("minimal")
  private minimal!: boolean;

  get showNavigation() {
    return (
      this.$store.state.layout.showNavigation &&
      !(this.loggingIn || this.profileLoadingState === "errorCritical")
    );
  }

  get isLoggingIn() {
    const maintenance =
      process.env.VUE_APP_MAINTENANCE_MODE?.toLowerCase() === "true";

    return !maintenance && this.loggingIn;
  }

  @user.Getter("loggingIn")
  private loggingIn!: boolean;

  @user.Getter("profileLoadingState")
  private profileLoadingState!: ProfileState;

  mounted() {
    this.$store.commit(
      LAYOUT_MUTATIONS.setCurrentBrowserWidth,
      window.innerWidth
    );

    window.addEventListener(
      "resize",
      debounce(() => {
        this.$store.commit(
          LAYOUT_MUTATIONS.setCurrentBrowserWidth,
          window.innerWidth
        );
      }, 100)
    );
  }
}
